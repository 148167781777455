import React from "react";
import SEO from "../components/seo";

function NotFoundPage() {
	return (
		<div>
			<SEO title="Submission Received" />
			<div>
				<h2 className="bg-yellow inline-block my-8 p-3">
					Thank You!
					Your submission has been received. You will be sent an e-mail with your responses. Once your form has been imported into our system in the next 5 minutes, the online form will be deleted.
				</h2>
			</div>
		</div>
	);
}

export default NotFoundPage;
